import LogoTitle from './LogoTitle';
import NoSsr from '@mpth/react-no-ssr';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  DarkMode,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useTheme
} from '@chakra-ui/react';
import {FiMenu} from 'react-icons/fi';
import {GET_USER} from '../utils/queries';
import {Link as GatsbyLink} from 'gatsby';
import {Global} from '@emotion/react';
import {useQuery} from '@apollo/client';

export const HEADER_HEIGHT = 16;

function HeaderButton(props) {
  return <Button h="9" as={GatsbyLink} to="/projects" {...props} />;
}

function HeaderButtons({isDark}) {
  const {data, loading} = useQuery(GET_USER);

  if (loading) {
    return (
      <HeaderButton isLoading colorScheme="pink">
        Sign up
      </HeaderButton>
    );
  }

  if (data.me) {
    return <HeaderButton colorScheme="pink">My projects</HeaderButton>;
  }

  const logInButton = <HeaderButton variant="ghost">Log in</HeaderButton>;
  return (
    <ButtonGroup>
      {isDark ? <DarkMode>{logInButton}</DarkMode> : logInButton}
      <HeaderButton colorScheme="pink">Sign up</HeaderButton>
    </ButtonGroup>
  );
}

HeaderButtons.propTypes = {
  isDark: PropTypes.bool
};

function HeaderLinks({component = Link}) {
  return (
    <>
      {React.createElement(
        component,
        {
          as: GatsbyLink,
          to: '/'
        },
        'Home'
      )}
      {React.createElement(
        component,
        {
          as: GatsbyLink,
          to: '/guide'
        },
        'Guide'
      )}
      {React.createElement(
        component,
        {
          as: GatsbyLink,
          to: '/#pricing'
        },
        'Pricing'
      )}
    </>
  );
}

HeaderLinks.propTypes = {
  component: PropTypes.elementType
};

export default function Header({isDark, ...props}) {
  const {space} = useTheme();

  const menuButton = (
    <MenuButton
      as={IconButton}
      variant="ghost"
      fontSize="2xl"
      icon={<FiMenu />}
    />
  );

  return (
    <Box
      position="sticky"
      zIndex="sticky"
      top="0"
      as="header"
      bgColor="white"
      {...props}
    >
      <Global
        styles={{
          html: {
            scrollPaddingTop: space[HEADER_HEIGHT]
          }
        }}
      />
      <Flex
        mx="auto"
        maxW="container.xl"
        align="center"
        h={HEADER_HEIGHT}
        px="4"
      >
        <LogoTitle as={GatsbyLink} to="/" mr="auto" />
        <Box display={{md: 'none'}}>
          <Menu>
            {isDark ? <DarkMode>{menuButton}</DarkMode> : menuButton}
            <MenuList color="gray.800">
              <HeaderLinks component={MenuItem} />
              <MenuItem as={GatsbyLink} to="/projects">
                My projects
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <HStack
          spacing="6"
          display={{
            base: 'none',
            md: 'flex'
          }}
        >
          <HeaderLinks />
          <NoSsr>
            <HeaderButtons isDark={isDark} />
          </NoSsr>
        </HStack>
      </Flex>
    </Box>
  );
}

Header.propTypes = {
  isDark: PropTypes.bool
};
