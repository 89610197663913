import React from 'react';
import {Text} from '@chakra-ui/react';

export default function Colophon(props) {
  return (
    <Text {...props}>
      &copy; {new Date().getFullYear()} Really Awesome Doings
    </Text>
  );
}
