import React from 'react';
import {Box, Flex, Heading} from '@chakra-ui/react';
import {ReactComponent as Logo} from '../assets/logo.svg';

export default function LogoTitle(props) {
  return (
    <Flex align="center" {...props}>
      <Box as={Logo} boxSize={10} mr="3" />
      <Heading fontSize="3xl" letterSpacing="tighter" lineHeight="1">
        Playback
      </Heading>
    </Flex>
  );
}
