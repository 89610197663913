import clockTime from 'clock-time';
import seigaiha from '../assets/seigaiha.svg';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {GET_USER, PAYMENT_METHOD_FRAGMENT} from './queries';
import {createContext, useCallback, useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {useTheme} from '@chakra-ui/react';

export const NEW_BLOCK_ID = 'new';

export const UserContext = createContext();
export const ProjectContext = createContext();
export const ProjectsContext = createContext();
export const QueryContext = createContext();

export function isTyping(event) {
  // make sure that we don't respond to spaces in text fields
  return (
    event.target.tagName === 'INPUT' ||
    event.target.matches('[contenteditable]')
  );
}

export function getTimecode({start, end}) {
  return [start, end].map(time => clockTime(time * 1000)).join('-');
}

export const CONTAINER_PADDING_X = [6, 8, 10];
export const CONTAINER_PADDING_Y = [10, 12, 16];
export const CONTAINER_SPACING = [12, 16, 20];
export const CONTAINER_GAP = [5, 6, 8];

export const BILLING_WRAPPER_PROPS = {
  rounded: 'lg',
  borderWidth: '1px',
  borderColor: 'indigo.100',
  bgImage: `url(${seigaiha})`
};

export const CARD_ELEMENT_WRAPPER_PROPS = {
  w: 'full',
  px: 4,
  bg: 'white',
  rounded: 'md',
  borderWidth: '1px'
};

export function useCardElementOptions(overrides) {
  const {fonts, colors} = useTheme();
  return {
    style: {
      base: {
        lineHeight: '38px',
        fontSize: '16px',
        fontFamily: fonts.body,
        color: colors.gray[800],
        '::placeholder': {
          color: colors.gray[400]
        },
        ...overrides
      }
    }
  };
}

const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($token: String!) {
    createPaymentMethod(token: $token) {
      ...PaymentMethodFragment
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export function useCreatePaymentMethod(onCompleted) {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mutate] = useMutation(CREATE_PAYMENT_METHOD, {
    onCompleted() {
      window.fbq?.('track', 'AddPaymentInfo');
      onCompleted?.();
    },
    update(cache, {data}) {
      const queryOptions = {query: GET_USER};
      const {me} = cache.readQuery(queryOptions);
      cache.writeQuery({
        ...queryOptions,
        data: {
          me: {
            ...me,
            paymentMethod: data.createPaymentMethod
          }
        }
      });
    }
  });

  const createPaymentMethod = useCallback(
    async event => {
      event.preventDefault();

      setLoading(true);

      try {
        const result = await stripe.createToken(
          elements.getElement(CardElement)
        );

        if (result.error) {
          throw new Error(result.error.message);
        }

        await mutate({
          variables: {
            token: result.token.id
          }
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    },
    [stripe, elements, mutate]
  );

  return [
    createPaymentMethod,
    {
      loading,
      error,
      ready: stripe && elements
    }
  ];
}
