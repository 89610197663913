import {gql} from '@apollo/client';

export const BLOCK_FRAGMENT = gql`
  fragment BlockFragment on Block {
    id
    name
    start
    end
    description
  }
`;

export const PROJECT_FRAGMENT = gql`
  fragment ProjectFragment on Project {
    id
    name
    date
    notes
    duration
    file {
      name
      key
    }
    isOwn
    owner {
      name
    }
    isTrial
    blocks {
      ...BlockFragment
    }
  }
  ${BLOCK_FRAGMENT}
`;

export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    id
    brand
    last4
    expMonth
    expYear
  }
`;

export const LIST_PROJECTS = gql`
  {
    projects {
      ...ProjectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const COLLABORATOR_FRAGMENT = gql`
  fragment CollaboratorFragment on User {
    id
    name
    email
    active
    avatar @client
    role(projectId: $projectId)
  }
`;

export const GET_PROJECT = gql`
  query GetProject($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectFragment
      collaborators {
        ...CollaboratorFragment
      }
    }
  }
  ${PROJECT_FRAGMENT}
  ${COLLABORATOR_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    name
    email
    avatar @client
    updatedAt
  }
`;

export const GET_USER = gql`
  {
    me {
      ...UserFragment
      paymentMethod {
        ...PaymentMethodFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${PAYMENT_METHOD_FRAGMENT}
`;
